import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

// const routes: Routes = [
//   {
//     path: '',
//     redirectTo: 'root/home',
//     pathMatch: 'full'
//   },
//   {
//     path: 'root/home',
//     loadChildren: () => import('./root/root.module').then( m => m.RootPageModule)
//   }
// ];


const routes: Routes = [

      {  path: 'home',          loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),       pathMatch: 'full'    },
      {  path: 'guide',         loadChildren: () => import('./guide/guide.module').then( m => m.GuidePageModule),    pathMatch: 'full'    },
      {  path: 'style',         loadChildren: () => import('./style/style.module').then( m => m.StylePageModule),    pathMatch: 'full'    },
      {  path: 'project',       loadChildren: () => import('./project/project.module').then( m => m.ProjectPageModule),  pathMatch: 'full'    },
      {  path: 'explore',       loadChildren: () => import('./explore/explore.module').then( m => m.ExplorePageModule) , pathMatch: 'full'     },
      {
        path: 'explore/:id',
        loadChildren: () => import('./explore/explore.module').then( m => m.ExplorePageModule)
      },
      { path: '',  redirectTo: '/home',   pathMatch: 'full' },
      { path: '**',  redirectTo: '/home', pathMatch: 'full'} // case of any error
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
