import {Injectable, } from '@angular/core'
import {Platform} from '@ionic/angular'

@Injectable({
    providedIn: 'root'
  })
  export class ThemeService {
  
    darkMode = true;
   
    constructor(private plt: Platform) { 
        this.plt.ready().then(()=>{
            // const preferDark = window.matchMedia("(prefers-color-scheme: dark)");
            // preferDark.addEventListener("change", e => {
            //     this.setTheme(e.matches)
            // })
            this.setTheme(true)
        })
  
    }
  
toggleDarkTheme(){
this.darkMode = ! this.darkMode
this.setTheme(this.darkMode)
}    

setTheme(dark){
    if(dark){
        document.body.classList.add("dark")
    } else {
        document.body.classList.remove("dark")
    }

}
    

}
  

