import { Injectable, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import {DOCUMENT} from '@angular/common';
import { BehaviorSubject } from 'rxjs';


type ReadingDirection = 'ltr' | 'rtl';


@Injectable({
  providedIn: 'root'
})
export class TranslationConfigService {

  private _language = new BehaviorSubject<string>('en');

  get language() {
    return this._language.asObservable();
  }


  constructor(
    private translate: TranslateService, @Inject(DOCUMENT) private doc
  ) { }

  getDefaultLanguage() {
    let browserLanguage = this.translate.getBrowserLang();
    if (browserLanguage !== 'ar' && browserLanguage !== 'en' && browserLanguage !== 'fr' ){
      browserLanguage = 'en';
    }

    return browserLanguage;
   }

  setDefaultLanguage() {
    let browserLanguage = this.translate.getBrowserLang();
    if (browserLanguage !== 'ar' && browserLanguage !== 'en' && browserLanguage !== 'fr' ){
      browserLanguage = 'en';
    }
    this.translate.setDefaultLang(browserLanguage);
    if ( browserLanguage === 'ar') {
      this.doc.documentElement.dir = 'rtl';
    } else {
      this.doc.documentElement.dir = 'ltr';
    }
    this._language.next(browserLanguage);
    return browserLanguage;
  }

  setLanguage(setLang: string) {
    this.translate.use(setLang);
    if ( setLang === 'ar') {
         this.doc.documentElement.dir = 'rtl';
    } else {
     this.doc.documentElement.dir = 'ltr';
    }
    this._language.next(setLang);
   }

}












// import { Injectable } from '@angular/core';
// import { TranslateService } from '@ngx-translate/core';

// @Injectable({
//   providedIn: 'root'
// })
// export class TranslationConfigService {
//   constructor(
//     private translate: TranslateService
//   ) { }

//   getDefaultLanguage() {
//     const language = this.translate.getBrowserLang();
//     this.translate.setDefaultLang(language);
//     return language;
//   }

//   setLanguage(setLang) {
//     this.translate.use(setLang);
//   }

// }
