import { Component, OnInit } from '@angular/core';
import { ActionSheetController, Platform } from '@ionic/angular';
import { ThemeService } from './shared/services/theme.service';
import { TranslationConfigService } from './shared/services/translation-config.service';
import { SplashScreen } from '@capacitor/splash-screen';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})


export class AppComponent implements OnInit {

  selectedLanguage;
  contentId: string = "main-content-la";
  public appPages = [
    { title: 'GUIDE', url: '/guide', icon: 'book' },
    { title: 'STYLE', url: '/style', icon: 'heart' },
    { title: 'PROJECT', url: '/project', icon: 'star' },
    { title: 'EXPLORE', url: '/explore', icon: 'telescope' },
  ];
  // public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  constructor(private themeService: ThemeService,
    private platform: Platform,
    private translateConfigService: TranslationConfigService,
    private actionSheetCtrl: ActionSheetController,
  ) {
    this.initializeApp();
  }



  initializeApp() {
        this.translateConfigService.setDefaultLanguage();
        this.platform.ready().then(() => {           
               SplashScreen.hide();
        });
  }

  ngOnInit(){
    this.translateConfigService.language.subscribe(language=>{
      this.selectedLanguage = language
      language === 'ar' ? this.contentId = "main-content-ar" : this.contentId = "main-content-la"
      console.log(`selected language is now ${this.selectedLanguage}`)

    })
    }

  toggleDarkTheme(){
    // console.log("change mode detected")
    this.themeService.toggleDarkTheme();
  }
  


  onChangeLanguage() {
    // let element: HTMLElement = document.getElementById("lovelyMenu");
    this.actionSheetCtrl
    .create({
      header: 'Please choose a language',
      buttons: [
        {
          text: 'العربية',
          handler: () => {
            this.translateConfigService.setLanguage('ar');
            // element.setAttribute("side", "end");
          }
        },
        {
          text: 'English',
          handler: () => {
            this.translateConfigService.setLanguage('en');
            // element.setAttribute("side", "start");
          }
        },
        {
          text: 'Français',
          handler: () => {
            this.translateConfigService.setLanguage('fr');
            // element.setAttribute("side", "start");
          }
        },
        { text: 'Cancel', role: 'cancel' }
      ]
    })
    .then(actionSheetEl => {
      actionSheetEl.present();
    });

    
    // this.selectedLanguage = $event.target.value;
    // this.translateConfigService.setLanguage(this.selectedLanguage);
  }


}
